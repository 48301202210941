<template>
  <div
    class="app-new-item"
    v-loading.fullscreen.lock="loading"
    element-loading-spinner="el-icon-loading"
    element-loading-text="Desperately loading..."
    :style="{ width: sWidth }"
  >
    <div id="printDiv" v-show="printShow">
      <CompositionBreakdown
        :printModel="this.$data"
        style="height-max: 1000px"
      ></CompositionBreakdown>
    </div>
    <div class="new-item-head">
      Composition Breakdown {{ titleType }}
      <span
        style="position: absolute; left: 10px; cursor: pointer"
        @click="back"
      >
        <i class="el-icon-arrow-left"></i>
      </span>
      <span
        style="
          position: absolute;
          right: 10px;
          cursor: pointer;
          font-weight: 400;
          font-size: 14px;
        "
      >
        <el-dropdown placement v-if="userInfo.username">
          <div>
            <i class="el-icon-user" style="margin-right: 10px"></i>
            {{ userInfo.username }}
            <i class="el-icon-arrow-down"></i>
            <!-- <span class="user-name">语言切换</span> -->
            <el-dropdown-menu class="user-dropdown" slot="dropdown">
              <el-dropdown-item @click.native="logoutHandle"
                >Logout</el-dropdown-item
              >
            </el-dropdown-menu>
          </div>
        </el-dropdown>
      </span>
    </div>
    <div class="new-item-content">
      <!-- base info -->
      <div class="base-info">
        <div style="margin-left: 6%"></div>
        <div>
          <div>
            <span>Item Code：</span>
            <el-autocomplete
              clearable
              ref="itemCode"
              v-model="form.BaseInfoItemCode"
              :fetch-suggestions="codeSearch"
              placeholder="Please Input"
              popper-class="select-option"
              :popper-append-to-body="false"
              @select="itemCodeSearch"
            ></el-autocomplete>
          </div>
          <div>
            <span>Product Name：</span>
            <el-input
              v-model="form.BaseInfoProductName"
              placeholder="Please Input"
            ></el-input>
          </div>
          <div>
            <span>Product Manufactured By：</span>
            <el-input
              v-model="form.ProductManufacturedBy"
              placeholder="Please Input"
            ></el-input>
          </div>
        </div>
      </div>
      <!-- General Specification -->
        <div class="base-info">
          <div></div>
          <div>
            <div style="height: auto">
              <span>Common/Other Name：</span>
              <div
                style="display: inline-block; width: 615px; text-align: left">
                <div style="display: flex;justify: flex-start;flex-wrap: wrap;align-items: center;">
                  <el-tag
                    :key="i+200"
                    v-for="(tag, i) in commonOtherNameArr"
                    closable
                    :disable-transitions="false"
                    @click="tagHandle(commonOtherNameArr, tag, i)"
                    @close="handleClose(tag, 'commonOtherNameArr')"
                    >{{ tag }}</el-tag
                  >
                  <el-input
                    class="input-new-tag"
                    v-if="commonOtherNameInputVisible"
                    v-model="commonOtherNameInputValue"
                    ref="commonOtherNameSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'commonOtherNameInputValue',
                        'commonOtherNameArr',
                        'commonOtherNameInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'commonOtherNameInputValue',
                        'commonOtherNameArr',
                        'commonOtherNameInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    :disabled="commonOtherNameD"
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput(
                        'commonOtherNameInputVisible',
                        'commonOtherNameSaveTagInput'
                      )
                    "
                    >+ New Common/Other Name</el-button
                  >
                </div>
              </div>
            </div>
            <div style="height: auto">
              <span>INCI name：</span>
              <div
                style="display: inline-block; width: 615px; text-align: left"
              >
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                ><el-checkbox
                  v-model="form.showInciName"
                ></el-checkbox>
                  <el-tag
                    :key="i+300"
                    v-for="(tag, i) in inclNameArr"
                    closable
                    :disable-transitions="false"
                    @click="tagHandle(inclNameArr, tag, i)"
                    @close="handleClose(tag, 'inclNameArr')"
                    >{{ tag }}</el-tag
                  >
                  <el-input
                    class="input-new-tag"
                    v-if="inclNameInputVisible"
                    v-model="inclNameInputValue"
                    ref="inclNameSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'inclNameInputValue',
                        'inclNameArr',
                        'inclNameInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'inclNameInputValue',
                        'inclNameArr',
                        'inclNameInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    :disabled="inciNameD"
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput('inclNameInputVisible', 'inclNameSaveTagInput')
                    "
                    >+ New InclName</el-button
                  >
                </div>
              </div>
            </div>            
            <!-- <div>
              <span>Show INCI name：</span>
              <div class="el-input" style="text-align: left">
                <el-checkbox
                  v-model="form.showInciName"
                ></el-checkbox>
              </div>
            </div> -->
            <div style="height: auto">
              <span>Botanical Name：</span>
              <div
                style="display: inline-block; width: 615px; text-align: left"
              >
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                ><el-checkbox
                  v-model="form.showBotanicalName"
                ></el-checkbox>
                  <el-tag
                    :key="i+400"
                    v-for="(tag, i) in botanicalNameArr"
                    closable
                    :disable-transitions="false"
                    @click="tagHandle(botanicalNameArr, tag, i)"
                    @close="handleClose(tag, 'botanicalNameArr')"
                    >{{ tag }}</el-tag
                  >
                  <el-input
                    class="input-new-tag"
                    v-if="botanicalNameInputVisible"
                    v-model="botanicalNameInputValue"
                    ref="botanicalNameSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'botanicalNameInputValue',
                        'botanicalNameArr',
                        'botanicalNameInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'botanicalNameInputValue',
                        'botanicalNameArr',
                        'botanicalNameInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    :disabled="botanicalNameD"
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput(
                        'botanicalNameInputVisible',
                        'botanicalNameSaveTagInput'
                      )
                    "
                    >+ New botanicalName</el-button
                  >
                </div>
              </div>
            </div>
            <!-- <div>
              <span>Show Botanical Name：</span>
              <div class="el-input" style="text-align: left">
                <el-checkbox
                  v-model="form.showBotanicalName"
                ></el-checkbox>
              </div>
            </div> -->

            <div style="height: auto">
            <span>Percentage ：</span>
            <div style="display: inline-block; width: 615px; text-align: left">
              <div
                style="
                  display: flex;
                  justify: flex-start;
                  flex-wrap: wrap;
                  align-items: center;
                "
              >
                <el-tag
                  :key="i+500"
                  v-for="(tag, i) in percentageArr"
                  closable
                  :disable-transitions="false"
                  @click="tagHandle(percentageArr, tag, i)"
                  @close="handleClose(tag, 'percentageArr')"
                  >{{ tag }}</el-tag
                >
                <el-input
                  class="input-new-tag"
                  v-if="percentageInputVisible"
                  v-model="percentageInputValue"
                  ref="percentageSaveTagInput"
                  size="small"
                  @keyup.enter.native="
                    handleInputConfirm(
                      'percentageInputValue',
                      'percentageArr',
                      'percentageInputVisible'
                    )
                  "
                  @blur="
                    handleInputConfirm(
                      'percentageInputValue',
                      'percentageArr',
                      'percentageInputVisible'
                    )
                  "
                ></el-input>
                <el-button
                  v-else
                  class="button-new-tag"
                  size="small"
                  @click="
                    showInput(
                      'percentageInputVisible',
                      'percentageSaveTagInput'
                    )
                  "
                  >+ New Percentage</el-button
                >
              </div>
            </div>
            </div>
            <div>
              <span><el-input
                  v-model="form.CompCustomRowKey1"
                  class="custom-row"
                  placeholder="Please Input"
                  style="width: 50%"
                ></el-input>：</span>
              <div class="el-input" style="width: 190px;">
                <el-input
                  v-model="form.CompCustomRowValue1_1"
                  placeholder="Please Input"
                  style="margin-right: 10px;width: 180px"
                ></el-input>
              </div>
              <div class="el-input" style="width: 190px;">
                <el-input
                  v-model="form.CompCustomRowValue1_2"
                  placeholder="Please Input"
                  style="margin-right: 10px;width: 180px"
                ></el-input>
              </div>
              <div class="el-input" style="width: 190px;">
                <el-input
                  v-model="form.CompCustomRowValue1_3"
                  placeholder="Please Input"
                  style="width: 180px">
                </el-input>
              </div>
            <el-button type="primary" class="button-new-tag" size="small" @click="addCustomRow1" >+</el-button>
            </div>
            <template>
            <div 
            v-for="(item, index1) in CompCustomRowArr1"
            :key="index1+1">
              <span style="margin-left: 46px;"><el-input
                  v-model="item.CompCustomRowKey1"
                  class="custom-row"
                  placeholder="Please Input"
                  style="width: 50%"
                ></el-input>：</span>
              <div class="el-input" style="width: 190px;">
                <el-input
                  v-model="item.CompCustomRowValue1_1"
                  placeholder="Please Input"
                  style="margin-right: 10px;width: 180px"
                ></el-input>
              </div>
              <div class="el-input" style="width: 190px;">
                <el-input
                  v-model="item.CompCustomRowValue1_2"
                  placeholder="Please Input"
                  style="margin-right: 10px;width: 180px"
                ></el-input>
              </div>
              <div class="el-input" style="width: 190px;">
                <el-input
                  v-model="item.CompCustomRowValue1_3"
                  placeholder="Please Input"
                  style="width: 180px">
                </el-input>
              </div>
            <el-button type="primary" class="button-new-tag" size="small" @click="addCustomRow1" >+</el-button>
            <el-button type="primary" class="button-new-tag" size="small" @click="removeCustomRow1(index1)" >-</el-button>
            </div>
            </template>

            <div>
              <span>Maltodextrin：</span>
              <div class="el-input" style="text-align: left">
                <el-checkbox
                  v-model="form.IsMaltodextrin"
                  placeholder="Please Input"
                ></el-checkbox>
                <el-input
                  v-show="form.IsMaltodextrin"
                  v-model="form.Maltodextrin1"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px">
                </el-input>
                <el-input
                  v-show="form.IsMaltodextrin"
                  v-model="form.Maltodextrin2"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px">
                </el-input>
                <el-input
                  v-show="form.IsMaltodextrin"
                  v-model="form.Maltodextrin3"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px">
                </el-input>
              </div>
            </div>
            <div>
              <span>Water：</span>
              <div class="el-input" style="text-align: left">
                <el-checkbox
                  v-model="form.IsWater"
                  placeholder="Please Input"
                ></el-checkbox>
                <el-input
                  v-show="form.IsWater"
                  v-model="form.Water1"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px">
                </el-input>
                <el-input
                  v-show="form.IsWater"
                  v-model="form.Water2"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px">
                </el-input>
                <el-input
                  v-show="form.IsWater"
                  v-model="form.Water3"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px">
                </el-input>
              </div>
            </div>
            <div>
              <span>Glycerin：</span>
              <div class="el-input" style="text-align: left">
                <el-checkbox
                  v-model="form.IsGlycerin"
                  placeholder="Please Input"
                ></el-checkbox>
                <el-input
                  v-show="form.IsGlycerin"
                  v-model="form.Glycerin1"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px">
                </el-input>
                <el-input
                  v-show="form.IsGlycerin"
                  v-model="form.Glycerin2"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px">
                </el-input>
                <el-input
                  v-show="form.IsGlycerin"
                  v-model="form.Glycerin3"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px">
                </el-input>
              </div>
            </div>
            <div>
              <span>Butylene glycol：</span>
              <div class="el-input" style="text-align: left">
                <el-checkbox
                  v-model="form.IsButyleneGlycol"
                  placeholder="Please Input"
                ></el-checkbox>
                <el-input
                  v-show="form.IsButyleneGlycol"
                  v-model="form.ButyleneGlycol1"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px">
                </el-input>
                <el-input
                  v-show="form.IsButyleneGlycol"
                  v-model="form.ButyleneGlycol2"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px">
                </el-input>
                <el-input
                  v-show="form.IsButyleneGlycol"
                  v-model="form.ButyleneGlycol3"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px">
                </el-input>
              </div>
            </div>
            <div>
              <span>Propylene glycol：</span>
              <div class="el-input" style="text-align: left">
                <el-checkbox
                  v-model="form.IsPropyleneGlycol"
                  placeholder="Please Input"
                ></el-checkbox>
                <el-input
                  v-show="form.IsPropyleneGlycol"
                  v-model="form.PropyleneGlycol1"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px">
                </el-input>
                <el-input
                  v-show="form.IsPropyleneGlycol"
                  v-model="form.PropyleneGlycol2"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px">
                </el-input>
                <el-input
                  v-show="form.IsPropyleneGlycol"
                  v-model="form.PropyleneGlycol3"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px">
                </el-input>
              </div>
            </div>
            <div>
              <span><el-input
                  v-model="form.CompCustomRowKey2"
                  class="custom-row"
                  placeholder="Please Input"
                  style="width: 50%"
                ></el-input>：</span>
              <div class="el-input" style="width: 190px;">
                <el-input
                  v-model="form.CompCustomRowValue2_1"
                  placeholder="Please Input"
                  style="margin-right: 10px;width: 180px"
                ></el-input>
              </div>
              <div class="el-input" style="margin-right: 10px;width: 190px;">
                <el-input
                  v-model="form.CompCustomRowValue2_2"
                  placeholder="Please Input"
                  style="margin-right: 10px;width: 180px"
                ></el-input>
              </div>
              <div class="el-input" style="width: 190px;">
                <el-input
                  v-model="form.CompCustomRowValue2_3"
                  placeholder="Please Input"
                  style="width: 180px">
                </el-input>
              </div>
            <el-button type="primary" class="button-new-tag" size="small" @click="addCustomRow2" >+</el-button>
            </div>
            <template>
            <div 
            v-for="(item, index2) in CompCustomRowArr2"
            :key="index2+2000">
              <span style="margin-left: 46px;"><el-input
                  v-model="item.CompCustomRowKey2"
                  class="custom-row"
                  placeholder="Please Input"
                  style="width: 50%"
                ></el-input>：</span>
              <div class="el-input" style="width: 190px;">
                <el-input
                  v-model="item.CompCustomRowValue2_1"
                  placeholder="Please Input"
                  style="margin-right: 10px;width: 180px"
                ></el-input>
              </div>
              <div class="el-input" style="margin-right: 10px;width: 190px;">
                <el-input
                  v-model="item.CompCustomRowValue2_2"
                  placeholder="Please Input"
                  style="margin-right: 10px;width: 180px"
                ></el-input>
              </div>
              <div class="el-input" style="width: 190px;">
                <el-input
                  v-model="item.CompCustomRowValue2_3"
                  placeholder="Please Input"
                  style="width: 180px">
                </el-input>
              </div>
            <el-button type="primary" class="button-new-tag" size="small" @click="addCustomRow2" >+</el-button>
            <el-button type="primary" class="button-new-tag" size="small" @click="removeCustomRow2(index2)" >-</el-button>
            </div>
            </template>

            <div>
              <span>Optiphen Plus：</span>
              <div class="el-input" style="text-align: left">
                <el-checkbox
                  v-model="form.IsOptiphenPlus"
                  placeholder="Please Input"
                ></el-checkbox>
                <el-input
                  v-show="form.IsOptiphenPlus"
                  v-model="form.OptiphenPlus1"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px">
                </el-input>
                <el-input
                  v-show="form.IsOptiphenPlus"
                  v-model="form.OptiphenPlus2"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px">
                </el-input>
                <el-input
                  v-show="form.IsOptiphenPlus"
                  v-model="form.OptiphenPlus3"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px">
                </el-input>
              </div>
            </div>
            <div>
              <span>Phenonip：</span>
              <div class="el-input" style="text-align: left">
                <el-checkbox
                  v-model="form.IsPhenonip"
                  placeholder="Please Input"
                ></el-checkbox>
                <el-input
                  v-show="form.IsPhenonip"
                  v-model="form.Phenonip1"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px">
                </el-input>
                <el-input
                  v-show="form.IsPhenonip"
                  v-model="form.Phenonip2"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px">
                </el-input>
                <el-input
                  v-show="form.IsPhenonip"
                  v-model="form.Phenonip3"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px">
                </el-input>
              </div>
            </div>
            <div>
              <span>Phenoxyethanol：</span>
              <div class="el-input" style="text-align: left">
                <el-checkbox
                  v-model="form.IsPhenoxyethanol"
                  placeholder="Please Input"
                ></el-checkbox>
                <el-input
                  v-show="form.IsPhenoxyethanol"
                  v-model="form.Phenoxyethanol1"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px">
                </el-input>
                <el-input
                  v-show="form.IsPhenoxyethanol"
                  v-model="form.Phenoxyethanol2"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px">
                </el-input>
                <el-input
                  v-show="form.IsPhenoxyethanol"
                  v-model="form.Phenoxyethanol3"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px">
                </el-input>
              </div>
            </div>
            <div>
              <span>Potassium sorbate：</span>
              <div class="el-input" style="text-align: left">
                <el-checkbox
                  v-model="form.IsPotassiumSorbate"
                  placeholder="Please Input"
                ></el-checkbox>
                <el-input
                  v-show="form.IsPotassiumSorbate"
                  v-model="form.PotassiumSorbate1"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px"
                ></el-input>
                <el-input
                  v-show="form.IsPotassiumSorbate"
                  v-model="form.PotassiumSorbate2"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px"
                ></el-input>
                <el-input
                  v-show="form.IsPotassiumSorbate"
                  v-model="form.PotassiumSorbate3"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px"
                ></el-input>
              </div>
            </div>
            <div>
              <span>Sodium benzoate：</span>
              <div class="el-input" style="text-align: left">
                <el-checkbox
                  v-model="form.IsSodiumBenzoate"
                  placeholder="Please Input"
                ></el-checkbox>
                <el-input
                  v-show="form.IsSodiumBenzoate"
                  v-model="form.SodiumBenzoate1"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px"
                ></el-input>
                <el-input
                  v-show="form.IsSodiumBenzoate"
                  v-model="form.SodiumBenzoate2"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px"
                ></el-input>
                <el-input
                  v-show="form.IsSodiumBenzoate"
                  v-model="form.SodiumBenzoate3"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px"
                ></el-input>
              </div>
            </div>
            <div>
              <span>Citric acid：</span>
              <div class="el-input" style="text-align: left">
                <el-checkbox
                  v-model="form.IsCitricAcid"
                  placeholder="Please Input"
                ></el-checkbox>
                <el-input
                  v-show="form.IsCitricAcid"
                  v-model="form.CitricAcid1"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px"
                ></el-input>
                <el-input
                  v-show="form.IsCitricAcid"
                  v-model="form.CitricAcid2"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px"
                ></el-input>
                <el-input
                  v-show="form.IsCitricAcid"
                  v-model="form.CitricAcid3"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px"
                ></el-input>
              </div>
            </div>
            <div>
              <span>Phenoxyethanol and potassium sorbate：</span>
              <div class="el-input" style="text-align: left">
                <el-checkbox
                  v-model="form.IsPhenAndPoSor"
                  placeholder="Please Input"
                ></el-checkbox>
                <el-input
                  v-show="form.IsPhenAndPoSor"
                  v-model="form.PhenAndPoSor1"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px"
                ></el-input>
                <el-input
                  v-show="form.IsPhenAndPoSor"
                  v-model="form.PhenAndPoSor2"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px"
                ></el-input>
                <el-input
                  v-show="form.IsPhenAndPoSor"
                  v-model="form.PhenAndPoSor3"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px"
                ></el-input>
              </div>
            </div>
            <div>
              <span>Phenoxyethanol and sodium benzoate：</span>
              <div class="el-input" style="text-align: left">
                <el-checkbox
                  v-model="form.IsPhenAndSodBen"
                  placeholder="Please Input"
                ></el-checkbox>
                <el-input
                  v-show="form.IsPhenAndSodBen"
                  v-model="form.PhenAndSodBen1"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px"
                ></el-input>
                <el-input
                  v-show="form.IsPhenAndSodBen"
                  v-model="form.PhenAndSodBen2"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px"
                ></el-input>
                <el-input
                  v-show="form.IsPhenAndSodBen"
                  v-model="form.PhenAndSodBen3"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px"
                ></el-input>
              </div>
            </div>
            <div>
              <span style="width: 373px;">Phenoxyethanol, potassium sorbate and citric acid：</span>
              <div class="el-input" style="text-align: left">
                <el-checkbox
                  v-model="form.IsPhenAndPoSorCitAcid"
                  placeholder="Please Input"
                ></el-checkbox>
                <el-input
                  v-show="form.IsPhenAndPoSorCitAcid"
                  v-model="form.PhenAndPoSorCitAcid1"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px"
                ></el-input>
                <el-input
                  v-show="form.IsPhenAndPoSorCitAcid"
                  v-model="form.PhenAndPoSorCitAcid2"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px"
                ></el-input>
                <el-input
                  v-show="form.IsPhenAndPoSorCitAcid"
                  v-model="form.PhenAndPoSorCitAcid3"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px"
                ></el-input>
              </div>
            </div>
            <div>
              <span style="width: 373px;">Phenoxyethanol, sodium benzoate and citric acid：</span>
              <div class="el-input" style="text-align: left">
                <el-checkbox
                  v-model="form.IsPhenAndSodBenCitAcid"
                  placeholder="Please Input"
                ></el-checkbox>
                <el-input
                  v-show="form.IsPhenAndSodBenCitAcid"
                  v-model="form.PhenAndSodBenCitAcid1"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px"
                ></el-input>
                <el-input
                  v-show="form.IsPhenAndSodBenCitAcid"
                  v-model="form.PhenAndSodBenCitAcid2"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px"
                ></el-input>
                <el-input
                  v-show="form.IsPhenAndSodBenCitAcid"
                  v-model="form.PhenAndSodBenCitAcid3"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px"
                ></el-input>
              </div>
            </div>           
            
            <div>
              <span><el-input
                  v-model="form.CompCustomRowKey3"
                  class="custom-row"
                  placeholder="Please Input"
                  style="width: 50%"
                ></el-input>：</span>
              <div class="el-input" style="width: 190px;">
                <el-input
                  v-model="form.CompCustomRowValue3_1"
                  placeholder="Please Input"
                  style="margin-right: 10px;width: 180px"
                ></el-input>
              </div>
              <div class="el-input" style="width: 190px;">
                <el-input
                  v-model="form.CompCustomRowValue3_2"
                  placeholder="Please Input"
                  style="margin-right: 10px;width: 180px"
                ></el-input>
              </div>
              <div class="el-input" style="width: 190px;">
                <el-input
                  v-model="form.CompCustomRowValue3_3"
                  placeholder="Please Input"
                  style="width: 180px">
                </el-input>
              </div>
            <el-button type="primary" class="button-new-tag" size="small" @click="addCustomRow3" >+</el-button>
            </div>
            <template>
            <div 
            v-for="(item, index3) in CompCustomRowArr3"
            :key="index3+30000">
              <span style="margin-left: 46px;"><el-input
                  v-model="item.CompCustomRowKey3"
                  class="custom-row"
                  placeholder="Please Input"
                  style="width: 50%"
                ></el-input>：</span>
              <div class="el-input" style="width: 190px;">
                <el-input
                  v-model="item.CompCustomRowValue3_1"
                  placeholder="Please Input"
                  style="margin-right: 10px;width: 180px"
                ></el-input>
              </div>
              <div class="el-input" style="width: 190px;">
                <el-input
                  v-model="item.CompCustomRowValue3_2"
                  placeholder="Please Input"
                  style="margin-right: 10px;width: 180px"
                ></el-input>
              </div>
              <div class="el-input" style="width: 190px;">
                <el-input
                  v-model="item.CompCustomRowValue3_3"
                  placeholder="Please Input"
                  style="width: 180px">
                </el-input>
              </div>
            <el-button type="primary" class="button-new-tag" size="small" @click="addCustomRow3" >+</el-button>
            <el-button type="primary" class="button-new-tag" size="small" @click="removeCustomRow3(index3)" >-</el-button>
            </div>
            </template>

            <div>
              <span>Total：</span>
              <div class="el-input" style="text-align: left">
                <el-input
                  v-model="form.Total1"
                  placeholder="Please Input"
                  style="margin-right: 10px; width: 180px"
                ></el-input>
                <el-input
                  v-model="form.Total2"
                  placeholder="Please Input"
                  style="margin-right: 10px; width: 180px"
                ></el-input>
                <el-input
                  v-model="form.Total3"
                  placeholder="Please Input"
                  style="margin-right: 10px; width: 180px"
                ></el-input>
              </div>
            </div>
            <div style="margin-bottom: 220px;">
              <span></span>
              <div class="el-input" style="text-align: left">
                <el-checkbox
                  v-model="form.IsDetail"
                  placeholder="Please Input"
                ></el-checkbox>
                  Draco Natural Products, Inc., San Jose, California,
                  attests that the above product extract was 100% extracted from 
                  <br>
                <el-input
                  v-show="form.IsDetail"
                  v-model="form.Detail"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 180px"
                ></el-input>
                  using only filtered water, thus it is 100% natural.No other solvents were used during the controlled-temperature, spray dried extraction process.We only use heat for sterilization. No irradiation, ETO, nor Ozone is involved in all process. 
                
                
              </div>
            </div>  

          </div>
        </div>
        <!-- Note -->
        <!-- <div class="base-info">
          <div>Note:</div>
          <div style="width: 80%; text-align: left; font-weight: bold">
            <div>
              * Color variation from batch to batch of the product may occur due
              to natural variations of raw material.
            </div>
            <div>
              * The pH of liquid product is tested in its 5% aqueous solution,
              if carrier only contain pure glycerin, pure butylene, pure
              propylene or their blend.
            </div>
            <div>
              * Product was manufactured in a facility that also processes:
              other herbs, fruits and vegetables.
            </div>
            <div>
              * Some natural sediment may occur upon standing, that doesn’t
              compromise the quality of the product; please mix well before use
              in such case.
            </div>
          </div>
        </div> -->
        <!-- Save -->
        <div class="save-bottom">
          <el-button v-if="userInfo.operateStatus" type="primary" @click="handleNew('ContinueEdit')">Save</el-button>
          <el-button v-if="userInfo.operateStatus" type="primary" @click="handleNew('Save&New')">Save & New</el-button>
          <el-button @click="back">Back Home</el-button>
          <el-button type="success" icon="el-icon-printer" @click="printPage()">Print</el-button>
        </div>
    </div>
  </div>
</template>

<script>
import base from "@/common/base.js";
import printJS from "print-js";
import CompositionBreakdown from "./PrintProductTypeModule/CompositionBreakdown.vue";
export default {
  name: "composition",
  mixins: [base],
  components: { CompositionBreakdown },
  data() {
    return {
      titleType: "Add",
      sWidth: 0,
      loading: false,
      itemCodeSearchLock: "", 
      //#region 空白行
      CompCustomRowArr1:[],
      CompCustomRow1:{
        CompCustomRowKey1:"",
        CompCustomRowValue1_1:"",
        CompCustomRowValue1_2:"",
        CompCustomRowValue1_3:"",
      },
      CompCustomRowArr2:[],
      CompCustomRow2:{
        CompCustomRowKey2:"",
        CompCustomRowValue2_1:"",
        CompCustomRowValue2_2:"",
        CompCustomRowValue2_3:"",
      },
      CompCustomRowArr3:[],
      CompCustomRow3:{
        CompCustomRowKey3:"",
        CompCustomRowValue3_1:"",
        CompCustomRowValue3_2:"",
        CompCustomRowValue3_3:"",
      },
      //#endregion
      //#region 数组
      inclNameArr: [],
      inclNameInputVisible: "",
      inclNameInputValue: "",
      inciNameD: false,

      commonOtherNameArr: [],
      commonOtherNameInputVisible: "",
      commonOtherNameInputValue: "",
      commonOtherNameD: false,

      botanicalNameArr: [],
      botanicalNameInputVisible: "",
      botanicalNameInputValue: "",
      botanicalNameD: false,

      percentageArr: [],
      percentageInputVisible: "",
      percentageInputValue: "",
      percentageD: false,
      //#endregion
      form: {
        CompCustomRowKey1:"",
        CompCustomRowValue1_1:"",
        CompCustomRowValue1_2:"",
        CompCustomRowValue1_3:"",
        CompCustomRowKey2:"",
        CompCustomRowValue2_1:"",
        CompCustomRowValue2_2:"",
        CompCustomRowValue2_3:"",
        CompCustomRowKey3:"",
        CompCustomRowValue3_1:"",
        CompCustomRowValue3_2:"",
        CompCustomRowValue3_3:"",

        showInciName:true,
        showBotanicalName:true,
        BaseInfoItemCode: "",
        BaseInfoProductName: "",
        ProductManufacturedBy:"Draco Natural Products, Inc.539 Parrott StreetSan Jose, CA  95112",
        CompCustomRow1:"",
        CompCustomRow2:"",
        CompCustomRow3:"",
        CompCommonName: "",
        CompINCIName: "",
        CompBotanicalName: "",
        CompPercentage: "",

        IsMaltodextrin:false,
        Maltodextrin1:"",
        Maltodextrin2:"",
        Maltodextrin3:"",
        IsWater:false,
        Water1:"",
        Water2:"",
        Water3:"",
        IsGlycerin:false,
        Glycerin1:"",
        Glycerin2:"",
        Glycerin3:"",
        IsButyleneGlycol:false,
        ButyleneGlycol1:"",
        ButyleneGlycol2:"",
        ButyleneGlycol3:"",
        IsPropyleneGlycol:false,
        PropyleneGlycol1:"",
        PropyleneGlycol2:"",
        PropyleneGlycol3:"",
        IsOptiphenPlus:false,
        OptiphenPlus1:"",
        OptiphenPlus2:"",
        OptiphenPlus3:"",
        IsPhenonip:false,
        Phenonip1:"",
        Phenonip2:"",
        Phenonip3:"",
        IsPhenoxyethanol:false,
        Phenoxyethanol1:"",
        Phenoxyethanol2:"",
        Phenoxyethanol3:"",
        IsPotassiumSorbate:false,
        PotassiumSorbate1:"",
        PotassiumSorbate2:"",
        PotassiumSorbate3:"",
        IsSodiumBenzoate:false,
        SodiumBenzoate1:"",
        SodiumBenzoate2:"",
        SodiumBenzoate3:"",
        IsCitricAcid:false,
        CitricAcid1:"",
        CitricAcid2:"",
        CitricAcid3:"",
        IsPhenAndPoSor:false,
        PhenAndPoSor1:"",
        PhenAndPoSor2:"",
        PhenAndPoSor3:"",
        IsPhenAndSodBen:false,
        PhenAndSodBen1:"",
        PhenAndSodBen2:"",
        PhenAndSodBen3:"",
        IsPhenAndPoSorCitAcid:false,
        PhenAndPoSorCitAcid1:"",
        PhenAndPoSorCitAcid2:"",
        PhenAndPoSorCitAcid3:"",
        IsPhenAndSodBenCitAcid:false,
        PhenAndSodBenCitAcid1:"",
        PhenAndSodBenCitAcid2:"",
        PhenAndSodBenCitAcid3:"",
        Total1:"",
        Total2:"",
        Total3:"",
        IsDetail:false,
        Detail:"",
      },
      formData: {
        id: 0,
        productName: "",
        itemCode: "",
        jsonValue: {},
        editName:"",
        createTime:base.methods.getLocalTime(-7),
      },
      printShow: false,
      printObj: {
        id: "printDiv", // 这里是要打印元素的ID
        popTitle: "", // 打印的标题
        extraCss: "", // 打印可引入外部的一个 css 文件
        extraHead: "", // 打印头部文字
      },
      createTime: base.methods.getLocalTime(-7),
    };
  },
  computed: {},
  methods: {
    back() {
      this.$router.go(-1);
      // this.$router.push({ path: "/home" });
    },
    codeSearch(queryString, cb) {
      this.getDetailByItemCodeSearch(queryString);
      // var restaurants = this.$store.state.codeList;
      var codeList = JSON.parse(localStorage.getItem("itemCodeListKey"));
      var list = [];
      codeList.forEach((element) => {
        list.push({ value: element.itemCode });
      });
      // var restaurants = localStorage.getItem('itemCodeListKey');
      var results = queryString
        ? list.filter(this.createFilter(queryString))
        : list;
      // 调用 callback 返回建议列表的数据

      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) !=
            -1 || restaurant.value.indexOf(queryString) != -1
        );
      };
    },
    itemCodeSearch(val) {
      if (typeof val.value != "undefined") {
        console.info(val.value);
        this.getDetailByItemCodeSearch(val.value);
      }
    },
    showInput: function (inputVisible, ref) {
      console.log(inputVisible);
      this[inputVisible] = true;
      this.$nextTick(() => {
        this.$refs[ref].$refs.input.focus();
      });
    },
    handleInputConfirm(value, arr, visible) {
      let inputValue = this[value];
      if (inputValue) {
        console.info(this[arr]);
        this[arr].push(inputValue);
      }

      this[visible] = false;
      this[value] = "";
    },
    strSplit(context, type) {
      this[type] = []; //清理旧有数据防止重复
      if (!context) {
        return;
      }
      let splitArr = context.split("_");
      if (splitArr.length == 1) {
        splitArr = context.split(",");
      }
      splitArr.forEach((v) => {
        if (v.trim().length != 0) {
          this[type].push(v);
        }
      });
    },
    getDetailByItemCodeSearch(searchValue) {
      if (this.itemCodeSearchLock == searchValue) {
        this.itemCodeSearchLock = searchValue;
        return;
      }
      if (typeof searchValue == "undefined" || searchValue.length == 0) {
        return;
      }

      this.$api.handleSpec
        .getDetailByItemCode(searchValue)
        .then((res) => {
          if (res != null) {
            console.log("res")
            console.log(res)
            this.form.BaseInfoProductName = res.productName;
            this.strSplit(res.inciName, "inclNameArr");
            this.strSplit(res.commonOtherName, "commonOtherNameArr");
            this.strSplit(res.botanicalName, "botanicalNameArr");
            this.strSplit(res.percentage, "percentageArr");
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    printPage() {
      this.printShow = true;
      const style = "@page {margin:0} @media print{}";
      var printFileName = ""; 
      if(this.form.BaseInfoProductName!=""&&this.form.BaseInfoProductName!=undefined){
        printFileName ="(Composition) "+ this.form.BaseInfoProductName.replace(":","~").replace("：", "~");
      }
      document.title = printFileName;
      setTimeout(() => {
        printJS({
          printable: "printDiv",
          type: "html",
          style: style,
          scanStyles: false,
        });
      }, 1.5);
      setTimeout(() => {
        this.printShow = false;
      }, 3);
    },
    tagHandle(array, tagCotext, index) {
      //tag标签点击事件
      console.info(array, tagCotext, index);
      this.$prompt("修改值-->" + tagCotext, "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          array.splice(index, 1, value);
        })
        .catch(() => {});
    }, // ingredients标签操作
    handleClose(tag, arr) {
      this[arr].splice(this[arr].indexOf(tag), 1);
    },
    handleNew(type) {
      let params = { ...this.$route.query };
      console.log("=============");
      this.form.CompINCIName = this.inclNameArr.join("_");
      this.form.CompCommonOtherName = this.commonOtherNameArr.join("_");
      this.form.CompBotanicalName = this.botanicalNameArr.join("_");
      this.form.CompPercentage = this.percentageArr.join("_");
      
      this.form.CompCustomRow1 = JSON.stringify(this.CompCustomRowArr1)
      this.form.CompCustomRow2 = JSON.stringify(this.CompCustomRowArr2)
      this.form.CompCustomRow3 = JSON.stringify(this.CompCustomRowArr3)

      let jsonStr = JSON.stringify(this.form);
      console.log(jsonStr);
      this.formData.productName = this.form.BaseInfoProductName;
      this.formData.itemCode = this.form.BaseInfoItemCode;
      this.formData.productManufactured = this.form.ProductManufacturedBy;
      this.formData.jsonValue = jsonStr;
      this.createTime = base.methods.getLocalTime(-7);
      console.log(this.formData);
      if (params.id) {
        this.formData.id = params.id;
        this.$api.handleComp
          .editCompItem(this.formData)
          .then(() => {
            this.$message({
              type: "success",
              message: "SuccessFul",
            });
            // this.$router.push({
            //   path: "home",
            // });
            this.reloadPage(type);
            this.addToLibrary()
          })
          .catch((err) => {
            this.$message({
              type: "error",
              message: err,
            });
            console.log(err);
          });
      } else {
        this.$api.handleComp
          .addCompItem(this.formData)
          .then((res) => {
            console.log(res);
            this.$message({
              type: "success",
              message: "SuccessFul",
            });
            // this.$router.push({
            //   path: "home",
            // });
            this.reloadPage(type);
          })
          .catch((err) => {
            this.$message({
              type: "error",
              message: err,
            });
            console.log(err);
          });
      }
    },
    reloadPage(type){
      if (type == "Save&New") {
        //清空数据
        this.$router.push({
          path: "new-item-comp",
          query: { id: undefined },
        });
        this.$router.go(0);
      }
    },
    getDetailById(params) {
      this.loading = true;
      this.$api.handleComp
      .getCompDetail(params)
      .then((data) => {
        console.log("data");
        console.log(data);
        this.formData = data;
        this.createTime = this.formatTime(data.createTime, "yyyy-MM-dd");
        this.form = JSON.parse(data.jsonValue);
        if (this.form.BaseInfoManufacturingDate == "NaN-aN-aN") {
          this.form.BaseInfoManufacturingDate = "";
          this.form.BaseInfoExpirationDate = "";
        }
        this.loading = false;

        this.strSplit(this.form.CompINCIName, "inclNameArr");
        this.strSplit(this.form.CompCommonOtherName, "commonOtherNameArr");
        this.strSplit(this.form.CompBotanicalName, "botanicalNameArr");
        this.strSplit(this.form.CompPercentage, "percentageArr");
        this.CompCustomRowArr1 = JSON.parse(this.form.CompCustomRow1)
        this.CompCustomRowArr2 = JSON.parse(this.form.CompCustomRow2)
        this.CompCustomRowArr3 = JSON.parse(this.form.CompCustomRow3)
      });
    },
    addCustomRow1(){
      this.CompCustomRowArr1.push({ ...this.CompCustomRow1 });
    },
    removeCustomRow1(index){
      this.$delete(this.CompCustomRowArr1,index)
    },
    addCustomRow2(){
      this.CompCustomRowArr2.push({ ...this.CompCustomRow2 });
    },
    removeCustomRow2(index){
      this.$delete(this.CompCustomRowArr2,index)
    },
    addCustomRow3(){
      this.CompCustomRowArr3.push({ ...this.CompCustomRow3 });
    },
    removeCustomRow3(index){
      this.$delete(this.CompCustomRowArr3,index)
    },
    addToLibrary(){
      this.$confirm(
        "Are you sure add to Library. Continue?",
        "Tips",
        {
          confirmButtonText: "Ok",
          cancelButtonText: "Cancel",
          distinguishCancelAndClose: true,
          type: "warning",
        }
      ).then(() => {
        let param = { ...this.$route.query };
        let params ={ids:[],type:'composition'}
        let ids=[]
        ids.push(param.id)
        params.ids = ids
        
        console.log('addToLibrary======params')
        console.log(params)
        this.$api
          .batchAddLibrary(params)
          .then(() => {
            this.$message({ type: "success", message: "Add SuccessFully" });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
     
  },
  mounted() {
    let specUserInfo = JSON.parse(localStorage.getItem("specUserInfo"));
    if(specUserInfo){
      this.formData.editName = specUserInfo.username;
    }
    this.titleType = "Add";
    this.createTime = new Date(); 
    let params = { ...this.$route.query };
    if (params.id) {
      this.titleType = "Edit";
      this.getDetailById(params);
      this.newItemType = "Edit";
    } else {
      this.newItemType = "New";
    }
    this.sWidth = "1920px";
  },
  created() {},
 
};
</script>
<style lang="scss" scoped>
@import "./module/NewItemStyle.scss";
</style>


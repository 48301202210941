<template>
  <div class="page" style="text-align: left; padding: 45px">
    <div class="row">
      <div style="width: 99%">
        <div class="header" id="header" style="width: 100%; height: 100px">
          <div style="width: 11%; float: left">
            <img
              src="../../assets/print_logo.jpg"
              style="width: 100px; height: 100px"
            />
          </div>
          <div style="width: 89%; float: right">
            <p
              style="
                font-size: 28px;
                font-family: Times New Roman;
                text-align: center;
                vertical-align: text-top;
              "
            >
              <b>Draco Natural Products, Inc.</b>
              <!-- <b v-if="printModel.form.BaseInfoEditPrintTitleCheckbox==false">Draco Natural Products, Inc.</b>
              <b v-if="printModel.form.BaseInfoEditPrintTitleCheckbox">{{printModel.form.BaseInfoEditPrintTitle}}</b> -->
            </p>
            <p
              style="
                font-size: 28px;
                font-family: Times New Roman;
                text-align: center;
                vertical-align: text-top;
              "
            >
              <b><u>COMPOSITION BREAKDOWN STATEMENT</u></b>
            </p>
          </div>
        </div>

        <!-- Product info -->
        <table style="width: 99%">
          <tr v-show="printModel.form.BaseInfoProductName">
            <td style="width: 25%"><b>Product Name</b></td>
            <td style="width: 75%">
              {{ printModel.form.BaseInfoProductName }}
            </td>
          </tr>
          <tr>
            <td v-show="printModel.form.BaseInfoItemCode" style="width: 25%">
              <b>Item Code</b>
            </td>
            <td v-show="printModel.form.BaseInfoItemCode" style="width: 25%">
              {{ printModel.form.BaseInfoItemCode }}
            </td>
          </tr>
          <tr>
            <td v-show="printModel.form.ProductManufacturedBy" style="width: 25%">
              <b>Product Manufactured By</b>
            </td>
            <td v-show="printModel.form.ProductManufacturedBy" style="width: 25%">
              {{ printModel.form.ProductManufacturedBy }}
            </td>
          </tr>
        </table>
        <!-- General Specification -->
        <div style="font-size: 18px">
          <b><u></u></b>
        </div>

        <!-- 遍历table -->
        <template v-if="
          printModel.commonOtherNameArr.length > 0 || 
          printModel.inclNameArr.length > 0 || 
          printModel.botanicalNameArr.length > 0 || 
          printModel.percentageArr.length > 0 || 
          printModel.CompCustomRowArr1.length > 0">
          <table
            style="width: 98%;border-collapse: collapse;"
            cellspacing="0"
            class="tab"
          >
            <thead>
              <tr style="text-align: center;border-bottom:2px solid gray">
                <th>Common/Other Name</th>
                <th v-show="printModel.form.showInciName">INCI Name</th>
                <th v-show="printModel.form.showBotanicalName">Botanical Name</th>
                <th>Percentage </th>
              </tr>
            </thead>
            <tbody>
              <tr :key="i+100" v-for="(tag, i) in maxArr">
                <td>
                  <div v-show="i + 1 <= printModel.commonOtherNameArr.length">{{
                    printModel.commonOtherNameArr[i]
                  }}</div>
                </td>
                <td v-show="printModel.form.showInciName">
                  <div v-show="i + 1 <= printModel.inclNameArr.length">{{
                    printModel.inclNameArr[i]
                  }}</div>
                </td>
                <td v-show="printModel.form.showBotanicalName">
                  <div
                    v-show="i + 1 <= printModel.botanicalNameArr.length"
                    >{{ printModel.botanicalNameArr[i] }}</div
                  >
                </td>
                <td>
                  <div v-show="i + 1 <= printModel.percentageArr.length">{{
                    printModel.percentageArr[i]
                  }}</div>
                </td>
              </tr>
              <tr v-show="printModel.form.CompCustomRowKey1 > 0">
                <td>{{printModel.form.CompCustomRowKey1}}</td>
                <td v-show="printModel.form.showInciName">{{printModel.form.CompCustomRowValue1_1}}</td>
                <td v-show="printModel.form.showBotanicalName">{{printModel.form.CompCustomRowValue1_2}}</td>
                <td>{{printModel.form.CompCustomRowValue1_3}}</td>
              </tr>
              <tr v-show="printModel.CompCustomRowArr1.length > 0" v-for="(item, index) in printModel.CompCustomRowArr1"
                :key="index+1000">
                <td>{{item.CompCustomRowKey1}}</td>
                <td v-show="printModel.form.showInciName">{{item.CompCustomRowValue1_1}}</td>
                <td v-show="printModel.form.showBotanicalName">{{item.CompCustomRowValue1_2}}</td>
                <td>{{item.CompCustomRowValue1_3}}</td>
              </tr>
              <tr v-show="printModel.form.IsMaltodextrin">
                <td>Maltodextrin</td>
                <td v-show="printModel.form.showInciName">{{printModel.form.Maltodextrin1}}</td>
                <td v-show="printModel.form.showBotanicalName">{{printModel.form.Maltodextrin2}}</td>
                <td>{{printModel.form.Maltodextrin3}}</td>
              </tr>
              <tr v-show="printModel.form.IsWater">
                <td>Water</td>
                <td v-show="printModel.form.showInciName">{{printModel.form.Water1}}</td>
                <td v-show="printModel.form.showBotanicalName">{{printModel.form.Water2}}</td>
                <td>{{printModel.form.Water3}}</td>
              </tr>
              <tr v-show="printModel.form.IsGlycerin">
                <td>Glycerin</td>
                <td v-show="printModel.form.showInciName">{{printModel.form.Glycerin1}}</td>
                <td v-show="printModel.form.showBotanicalName">{{printModel.form.Glycerin2}}</td>
                <td>{{printModel.form.Glycerin3}}</td>
              </tr>
              <tr v-show="printModel.form.IsButyleneGlycol">
                <td>Butylene glycol</td>
                <td v-show="printModel.form.showInciName">{{printModel.form.ButyleneGlycol1}}</td>
                <td v-show="printModel.form.showBotanicalName">{{printModel.form.ButyleneGlycol2}}</td>
                <td>{{printModel.form.ButyleneGlycol3}}</td>
              </tr>
              <tr v-show="printModel.form.IsPropyleneGlycol">
                <td>Propylene glycol</td>
                <td v-show="printModel.form.showInciName">{{printModel.form.PropyleneGlycol1}}</td>
                <td v-show="printModel.form.showBotanicalName">{{printModel.form.PropyleneGlycol2}}</td>
                <td>{{printModel.form.PropyleneGlycol3}}</td>
              </tr>
              <tr v-show="printModel.form.CompCustomRowKey2 > 0">
                <td>{{printModel.form.CompCustomRowKey2}}</td>
                <td v-show="printModel.form.showInciName">{{printModel.form.CompCustomRowValue2_1}}</td>
                <td v-show="printModel.form.showBotanicalName">{{printModel.form.CompCustomRowValue2_2}}</td>
                <td>{{printModel.form.CompCustomRowValue2_3}}</td>
              </tr>
              <tr v-for="(item, index) in printModel.CompCustomRowArr2"
                :key="index+2000">
                <td>{{item.CompCustomRowKey2}}</td>
                <td v-show="printModel.form.showInciName">{{item.CompCustomRowValue2_1}}</td>
                <td v-show="printModel.form.showBotanicalName">{{item.CompCustomRowValue2_2}}</td>
                <td>{{item.CompCustomRowValue2_3}}</td>
              </tr>
              <tr v-show="printModel.form.IsOptiphenPlus">
                <td>Optiphen Plus</td>
                <td v-show="printModel.form.showInciName">{{printModel.form.OptiphenPlus1}}</td>
                <td v-show="printModel.form.showBotanicalName">{{printModel.form.OptiphenPlus2}}</td>
                <td>{{printModel.form.OptiphenPlus3}}</td>
              </tr>
              <tr v-show="printModel.form.IsPhenonip">
                <td>Phenonip</td>
                <td v-show="printModel.form.showInciName">{{printModel.form.Phenonip1}}</td>
                <td v-show="printModel.form.showBotanicalName">{{printModel.form.Phenonip2}}</td>
                <td>{{printModel.form.Phenonip3}}</td>
              </tr>
              <tr v-show="printModel.form.IsPhenoxyethanol">
                <td>Phenoxyethanol</td>
                <td v-show="printModel.form.showInciName">{{printModel.form.Phenoxyethanol1}}</td>
                <td v-show="printModel.form.showBotanicalName">{{printModel.form.Phenoxyethanol2}}</td>
                <td>{{printModel.form.Phenoxyethanol3}}</td>
              </tr>
              <tr v-show="printModel.form.IsPotassiumSorbate">
                <td>Potassium sorbate</td>
                <td v-show="printModel.form.showInciName">{{printModel.form.PotassiumSorbate1}}</td>
                <td v-show="printModel.form.showBotanicalName">{{printModel.form.PotassiumSorbate2}}</td>
                <td>{{printModel.form.PotassiumSorbate3}}</td>
              </tr>
              <tr v-show="printModel.form.IsSodiumBenzoate">
                <td>Sodium benzoate</td>
                <td v-show="printModel.form.showInciName">{{printModel.form.SodiumBenzoate1}}</td>
                <td v-show="printModel.form.showBotanicalName">{{printModel.form.SodiumBenzoate2}}</td>
                <td>{{printModel.form.SodiumBenzoate3}}</td>
              </tr>
              <tr v-show="printModel.form.IsCitricAcid">
                <td>Citric acid</td>
                <td v-show="printModel.form.showInciName">{{printModel.form.CitricAcid1}}</td>
                <td v-show="printModel.form.showBotanicalName">{{printModel.form.CitricAcid2}}</td>
                <td>{{printModel.form.CitricAcid3}}</td>
              </tr>
              <tr v-show="printModel.form.IsPhenAndPoSor">
                <td>Phenoxyethanol and potassium sorbate</td>
                <td v-show="printModel.form.showInciName">{{printModel.form.PhenAndPoSor1}}</td>
                <td v-show="printModel.form.showBotanicalName">{{printModel.form.PhenAndPoSor2}}</td>
                <td>{{printModel.form.PhenAndPoSor3}}</td>
              </tr>
              <tr v-show="printModel.form.IsPhenAndSodBen">
                <td>Phenoxyethanol and sodium benzoate</td>
                <td v-show="printModel.form.showInciName">{{printModel.form.PhenAndSodBen1}}</td>
                <td v-show="printModel.form.showBotanicalName">{{printModel.form.PhenAndSodBen2}}</td>
                <td>{{printModel.form.PhenAndSodBen3}}</td>
              </tr>
              <tr v-show="printModel.form.IsPhenAndPoSorCitAcid">
                <td>Phenoxyethanol, potassium sorbate and citric acid</td>
                <td v-show="printModel.form.showInciName">{{printModel.form.PhenAndPoSorCitAcid1}}</td>
                <td v-show="printModel.form.showBotanicalName">{{printModel.form.PhenAndPoSorCitAcid2}}</td>
                <td>{{printModel.form.PhenAndPoSorCitAcid3}}</td>
              </tr>
              <tr v-show="printModel.form.IsPhenAndSodBenCitAcid">
                <td>Phenoxyethanol, sodium benzoate and citric acid</td>
                <td v-show="printModel.form.showInciName">{{printModel.form.PhenAndSodBenCitAcid1}}</td>
                <td v-show="printModel.form.showBotanicalName">{{printModel.form.PhenAndSodBenCitAcid2}}</td>
                <td>{{printModel.form.PhenAndSodBenCitAcid3}}</td>
              </tr>
              <tr v-show="printModel.form.CompCustomRowKey3 > 0">
                <td>{{printModel.form.CompCustomRowKey3}}</td>
                <td v-show="printModel.form.showInciName">{{printModel.form.CompCustomRowValue3_1}}</td>
                <td v-show="printModel.form.showBotanicalName">{{printModel.form.CompCustomRowValue3_2}}</td>
                <td>{{printModel.form.CompCustomRowValue3_3}}</td>
              </tr>
              <tr v-show="printModel.CompCustomRowArr3.length > 0" v-for="(item, index) in printModel.CompCustomRowArr3"
                :key="index+3000">
                <td>{{item.CompCustomRowKey3}}</td>
                <td v-show="printModel.form.showInciName">{{item.CompCustomRowValue3_1}}</td>
                <td v-show="printModel.form.showBotanicalName">{{item.CompCustomRowValue3_2}}</td>
                <td>{{item.CompCustomRowValue3_3}}</td>
              </tr>
              <tr v-show="printModel.form.Total1 || printModel.form.Total2 || printModel.form.Total3">
                <td>Total</td>
                <td v-show="printModel.form.showInciName">{{printModel.form.Total1}}</td>
                <td v-show="printModel.form.showBotanicalName">{{printModel.form.Total2}}</td>
                <td>{{printModel.form.Total3}}</td>
              </tr>
            </tbody>
            </table>
        </template>
        <div v-show="printModel.form.IsDetail">
          Draco Natural Products, Inc., San Jose, California, 
          attests that the above product extract was 100% extracted from {{printModel.form.Detail}} 
          using only filtered water, thus it is 100% natural.No other solvents were used during the controlled-temperature, 
          spray dried extraction process.We only use heat for sterilization. No irradiation, ETO, nor Ozone is involved in all process. 
        </div>
        <div style="clear: both"></div>
        <div style="height: 20px"></div>
        <!-- <div style="font-size: 18px">
          <b><u>Note</u></b>
        </div>
        <div
          class="footer"
          id="footer"
          style="
            font-size: 11px;
            font-family: Times New Roman;
            margin-top: 10px;
          "
        >
          <div>
            <span
              ><i
                >* Color variation from batch to batch of the product may occur
                due to natural variations of raw material.</i
              ></span
            >
          </div>
          <div style="height: 10px"></div>
          <div>
            <span
              ><i
                >* The pH of liquid product is tested in its 5% aqueous
                solution, if carrier only contain pure glycerin, pure butylene,
                pure propylene or their blend.</i
              ></span
            >
          </div>
          <div style="height: 10px"></div>
          <div>
            <span
              ><i
                >* Product was manufactured in a facility that also processes:
                other herbs, fruits and vegetables.</i
              ></span
            >
          </div>
          <div style="height: 10px"></div>
          <div>
            <span
              ><i
                >* Some natural sediment may occur upon standing, that doesn’t
                compromise the quality of the product; please mix well before
                use in such case.</i
              ></span
            >
          </div>
          <div style="height: 10px"></div>
          <div>
            <b
              ><u>
                Disclaimer: The test results listed are accurate to the best of
                our knowledge based upon our lab instruments and test methods.
                These results should not be used as a final determination for
                use in a finished product. According to GMP regulations, the
                specifications, test methods, and test results must be
                independently qualified by the purchasing party. The appropriate
                use of this product is the sole responsibility of the user or
                the purchasing party. Draco only guarantees the product
                purchased for the product specifications listed on the
                certificate of analysis and will only refund the product if it
                does not meet this specification and is still resalable. Draco
                will not refund the purchase if the product has been damaged or
                has been blended with other ingredients by the purchaser and is
                not responsible for costs of other ingredients the product was
                blended with. It is the responsibility of the purchaser to test
                extracts individually or in small pilot pre-blends to confirm
                that it meets the quality specifications before it is used.</u
              ></b
            >
          </div>

          <div style="height: 10px"></div>
          <div style="text-align: center">
            <b
              ><u>
                539 Parrott Street San Jose CA, 95112 Tel: 408-287-7871
                Fax:408-287-8838</u
              ></b
            >
          </div>
        </div> -->

        <div style="height: 10px"></div>
        <div>
          <div style="float: left;">
            Quality Assurance- <span style="font-size:28px;font-family: 'Comic Sans MS', cursive;">{{printModel.formData.editName}}</span>
          </div>
          <div style="float: right;margin-right: 80px;">Date: {{formatDate(printModel.formData.createTime)}}</div>
        </div>
        <div style="height: 20px"></div>
        <div>
        </div>
        <FooterSigner style="padding-right: 5px;"></FooterSigner>
      </div>
    </div>
  </div>
</template>
<script>
import FooterSigner from "./FooterSigner";
import base from "@/common/base.js";
export default {
  name: "CompositionBreakdown",
  mixins: [base],
  components: { FooterSigner },
  props: ["printModel"],
  data() {
    return {
      manufacturingDate: "",
      expirationDate: ""
    };
  },
  computed: {
    maxArr() {
      let tmp = [
        this.printModel.inclNameArr.length,
        this.printModel.commonOtherNameArr.length,
        this.printModel.botanicalNameArr.length,
        this.printModel.percentageArr.length,
      ].sort();
      return tmp[tmp.length - 1];
    },
  },
  methods: {
    formatDate(time) {
      return base.methods.formatDate({ date: time });
    },
  },
  created() {
  },
  mounted() {
    console.log("====printModel====");
    console.log(this.printModel);
  },
};
</script>

<style scoped>
.tab tr td,
.tab thead th {
  padding: 5px;
}
</style>